.gradient-div {
    --border-width: 1px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato, sans-serif;
    font-size: 2.5rem;
    text-transform: uppercase;
    border-radius: var(--border-width);
}

.gradient-div::after {
    position: absolute;
    content: "";
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(60deg, #5f86f2, #a65ff2, #f25fd0, #f25f61, #f2cb5f, #abf25f, #5ff281, #5ff2f0);
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradientDiv 4s alternate infinite;
}

.gradient-loader {
    --border-width: 1px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato, sans-serif;
    font-size: 2.5rem;
    text-transform: uppercase;
    border-radius: var(--border-width);
}

.gradient-loader::after {
    position: absolute;
    content: "";
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(60deg, #5f86f2, #a65ff2, #f25fd0, #f25f61, #f2cb5f, #abf25f, #5ff281, #5ff2f0);
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradientDiv 4s alternate infinite;
}

@keyframes moveGradientDiv {
    50% {
        background-position: 100% 50%;
    }
}
